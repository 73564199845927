.title {
    margin: 0px 0px 10px 0px;
    text-align: center;
    font-size: 17px;
    line-height: 150%;
    color: #000;
    font-weight: bold;
    margin-top: 30px;
}

.button-p {
    margin: 0px 0px 10px 0px;
    text-align: center;
    font-size: 0px;
}